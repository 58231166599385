/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import { 
  Kookai, 
  Cloudeasier,
  Cloud,
  AirFrance, 
  Enedis,
  Integration,
  Python,
  Ruby,
  Typescript,
  Renault,
  VueJS,
  Epitech,
  TVShows as TVShowsImages,
  Ahlia,
  Kuruko,
  KurukoThumb,
  ErenThumb,
  Goku,
  GokuThumb,
  Gon,
  GonThumb,
  LeagueOfLegend,
  TheLastKingdom,
  LaCasaDePapel,
  MrRobot,
  LifeIsStrange,
  BreakingBad,
  Got,
  Brooklyn99,
  BNP,
  Cafe
} from 'assets/img'
import { Saitama, Play, SNK, Levi } from 'assets/img'
import {
  Eminem,
  FreezeRael,
  Pride,
  RickAstley,
  TwoOfUs
} from 'assets/sounds'
import { SNKVideo, KurokoVideo, DBZVideo, HXHVideo } from 'assets/videos'

const s3BucketURL = 'https://d1jy5ar4h726yo.cloudfront.net/'

export const getVideoUrl = (assetName: string): string => {
  return (s3BucketURL + 'videos/' + assetName)
}

export const getSoundUrl = (assetName: string): string => {
  return (s3BucketURL + 'music/' + assetName)
}

export interface Techno {
  frameworks: string[]
  bgColor: string
  icon: string
}

export interface TechnicalStackInterface {
  [key: string]: Techno
}

export const TechnicalStack: TechnicalStackInterface = {
  typescript: {
    frameworks: ['ReactJS', 'React Native', 'VueJS'],
    bgColor: '#3178c6',
    icon: Typescript,
  },
  integration: {
    frameworks: ['HTML5', 'CSS', 'SASS', 'LESS'],
    bgColor: '#f1662b',
    icon: Integration,
  },
  cloud: {
    frameworks: ['AWS', 'GCP'],
    bgColor: '#ffcb05',
    icon: Cloud,

  },
}

export enum TechnicalStackKeys {
  TYPESCRIPT = 1,
  PYTHON = 2,
  INTEGRATION = 3,
  CLOUD = 4,
  RUBY = 5,
}
export const SongsInformations: PlaylistProps[] = [
  {
    title: 'Cleaning Out My Closet',
    artist: 'Eminem',
    src: getSoundUrl(Eminem)
  },
  {
    title: 'Freeze Rael',
    artist: 'Freeze Corleone',
    src: getSoundUrl(FreezeRael)
  },
  {
    title: 'Pride',
    artist: 'Kendrick Lamar',
    src: getSoundUrl(Pride)
  },
  {
    title: 'Never Gonna Give You Up',
    artist: 'Rick Astley',
    src: getSoundUrl(RickAstley)
  },
  {
    title: 'Grover Washington',
    artist: 'Just The Two of Us',
    src: getSoundUrl(TwoOfUs)
  },
]

export const experiences: ModalPropsType[] = [
  {
    title: 'Frontend Engineer',
    bgColor: '#bfbfbf',
    subtitle: 'Renault Digital',
    date: 'Aout 2023 - Aujourd\'hui',
    stack: 'React Native | Typescript',
    source: Renault,
    location: 'Technocentre Renault',
    description: `𝗟𝗔 𝗠𝗜𝗦𝗦𝗜𝗢𝗡
    
    Développement d'une application mobile. (Confidentiel)
    
    𝗗𝗢𝗠𝗔𝗜𝗡𝗘 𝗗'𝗜𝗡𝗧𝗘𝗥𝗩𝗘𝗡𝗧𝗜𝗢𝗡
    
    ▻ Implémentation d'un eslintrc optimal et de différents snippets
    ▻ Implémentation des @Alias via webpack
    ▻ Mise en place du typage strict react-navigation 6
    ▻ Mise en place de fonctionnalités natives JAVA & Swift
    ▻ Mise en place de reducers et de sagas
    ▻ Implémentation de l'api et des routes
    ▻ Mise en place de tests unitaires jest
    ▻ Déploiement d'archives TestFlight
    ▻ Aide à la mise en place d'une CI-CD git
    
    ▻ Participation a des sessions fonctionnelles de brainstorm
    ▻ Revue de code et correction
    ▻ Formation de membres de l'équipe à l'écosystème REACT et au TypeScript
    ▻ Formation de membres l'équipe au flow GitHub
    ▻ Participation aux sessions metiers, backlogs, design
    ▻ Organisation de réunions d'équipes et de démos fonctionnelles
    ▻ Amélioration de la documentation
    ▻ Supervisation des livrables
    
    𝗘𝗡𝗩𝗜𝗥𝗢𝗡𝗡𝗘𝗠𝗘𝗡𝗧 𝗧𝗘𝗖𝗛𝗡𝗜𝗤𝗨𝗘
    
    Teams, Jira, Notion
    GitLab, Typescript (React Native, Redux, Saga, Jest), React Native Stylesheet`,
    mainTechno: [TechnicalStackKeys.PYTHON]
  },
  {
    title: 'Technical Lead Frontend',
    bgColor: '#003371',
    subtitle: 'Enedis',
    date: 'Septembre 2022 - Août 2023 ',
    stack: 'CSS | JS | C++',
    source: Enedis,
    location: 'Paris - France',
    description: `𝗟𝗔 𝗠𝗜𝗦𝗦𝗜𝗢𝗡

    Développement d'une application mobile web cross-plateforme basée sur une architecture atomic.
    
    𝗗𝗢𝗠𝗔𝗜𝗡𝗘 𝗗'𝗜𝗡𝗧𝗘𝗥𝗩𝗘𝗡𝗧𝗜𝗢𝗡
    
    ▻ Création de l'architecture du projet
    ▻ Création d'un eslintrc optimal et de différents snippets
    ▻ Mise en place des @Alias via webpack
    ▻ Création de tout les composants
    ▻ Création du système de navigation et mise en place du typage strict react-navigation 6
    ▻ Création du design système, du thème, et mise en place d'un typage strict
    ▻ Mise en place des reducers et des saga
    ▻ Implémentation de l'api et des routes
    ▻ Mise en place de tests unitaires jest
    ▻ Mise en place du storybook
    ▻ Aide à la conception des maquettes et de métriques UX
    ▻ Aide à la mise en place d'une CI-CD git
    
    ▻ Organisation du planning des sprints agiles
    ▻ Participation aux sessions metiers, backlogs, design
    ▻ Organisation de réunions de synchronisation d'équipes
    ▻ Création de la documentation (Onboarding, MOA)
    ▻ Supervisation des livrables
    
    𝗘𝗡𝗩𝗜𝗥𝗢𝗡𝗡𝗘𝗠𝗘𝗡𝗧 𝗧𝗘𝗖𝗛𝗡𝗜𝗤𝗨𝗘
    Teams, Jira, Notion
    GitHub, Typescript (React Native Web, Redux, Saga, Jest), React Native Stylesheet`,
    mainTechno: [TechnicalStackKeys.TYPESCRIPT]
  },
  {
    title: 'Frontend Engineer',
    bgColor: '#00A678',
    subtitle: 'BNP Paribas',
    stack: 'React-Native | React-Native-Web | Typescript',
    date: 'Juillet 2021 - Septembre 2022',
    source: BNP,
    location: 'Paris - France',
    description: `𝗟𝗔 𝗠𝗜𝗦𝗦𝗜𝗢𝗡

    Développement de la plateforme d'Épargne salariale & retraite collective
    
    𝗗𝗢𝗠𝗔𝗜𝗡𝗘 𝗗'𝗜𝗡𝗧𝗘𝗥𝗩𝗘𝗡𝗧𝗜𝗢𝗡
    
    ▻ Conception des composants
    ▻ Aide à la conception & développement de design-system
    ▻ Responsive design & développement de custom Hooks de Style.
    ▻ Conception & Mise en place de l'architecture du projet.
    ▻ Sprint SCRUM (Azure & Github).
    ▻ Documenter les solutions mises en oeuvre.
    
    𝗘𝗡𝗩𝗜𝗥𝗢𝗡𝗡𝗘𝗠𝗘𝗡𝗧 𝗧𝗘𝗖𝗛𝗡𝗜𝗤𝗨𝗘
    
    GitHub, javaScript (React Native, Redux, Saga), React Native Stylesheet`,
    mainTechno: [TechnicalStackKeys.TYPESCRIPT]
  },
  {
    title: 'Frontend Engineer',
    bgColor: '#c9bc98',
    subtitle: '@Café',
    stack: 'React-Native | React-JS | Typescript',
    date: 'Janvier 2021 - Juillet 2021',
    source: Cafe,
    location: 'Paris - France',
    description: `𝗟𝗔 𝗠𝗜𝗦𝗦𝗜𝗢𝗡

    Café makes Office and Remote work coordination simple.
    
    Café's mission is to connect people because social interactions bring both creativity and engagement! It uses data to monitor & improve companies' remote policy over time 🔥
    
    𝗗𝗢𝗠𝗔𝗜𝗡𝗘 𝗗'𝗜𝗡𝗧𝗘𝗥𝗩𝗘𝗡𝗧𝗜𝗢𝗡
    
    ▻ Conception des composants
    ▻ Aide à la conception & développement de design-system
    ▻ Responsive design & développement d'une librairie Styles (SCSS).
    ▻ Sprint SCRUM (Cycle & Github Projects).
    ▻ Documenter les solutions mises en oeuvre.
    
    𝗘𝗡𝗩𝗜𝗥𝗢𝗡𝗡𝗘𝗠𝗘𝗡𝗧 𝗧𝗘𝗖𝗛𝗡𝗜𝗤𝗨𝗘
    
    GitHub, Gatsby, javaScript (React Native, React JS), React Native Styles, Material UI`,
    mainTechno: [TechnicalStackKeys.TYPESCRIPT, TechnicalStackKeys.RUBY]
  },
  {
    title: 'Frontend Engineer',
    bgColor: '#092D32',
    subtitle: 'Kookaï',
    stack: 'CSS | JS | C++',
    date: 'Jul - Sep 20',
    source: Kookai,
    location: 'Paris - France',
    description: `⇨ 𝑴𝑨 𝑴𝑰𝑺𝑺𝑰𝑶𝑵 : Modifier et ajouter des données sur un outil de monitoring constitué d'une carte et de plusieurs graphiques répertoriant toutes les données liées aux coupures électriques en France.

  𝐃𝐨𝐦𝐚𝐢𝐧𝐞 𝐝'𝐢𝐧𝐭𝐞𝐫𝐯𝐞𝐧𝐭𝐢𝐨𝐧 :
  ➤ Modification des labels constituants les graphiques (React, Chart JS).
  ➤ Récupération des données nécessaires en base de données (Django, PGSQL).
  ➤ Modification des graphiques afin d'avoir un rendu plus adapté aux besoins d'Enedis. (Chart JS).
  ➤ Mise en place de tests unitaires Back-End (Pytest).
  ➤ Mise en place de l'automatisation des tests Back-End (Jenkins).

  𝐅𝐫𝐨𝐧𝐭-𝐄𝐧𝐝 : HTML, CSS, Javascript (React)
  𝐁𝐚𝐜𝐤-𝐄𝐧𝐝 : Python, Django
  𝐁𝐚𝐬𝐞 𝐝𝐞 𝐝𝐨𝐧𝐧𝐞𝐞 : PGSQL
  𝐓𝐞𝐬𝐭𝐢𝐧𝐠 : Pytest
  𝐃𝐞𝐯𝐎𝐩𝐬 : Jenkins
  𝐎𝐫𝐠𝐚𝐧𝐢𝐬𝐚𝐭𝐢𝐨𝐧 : Trello
  𝐕𝐞𝐫𝐬𝐢𝐨𝐧𝐧𝐢𝐧𝐠 : GIT
  `,
    mainTechno: [TechnicalStackKeys.TYPESCRIPT, TechnicalStackKeys.RUBY]
  },
]

export const schools = [
  {
    title: 'Master’s degree in Computer Science',
    subtitle: 'Epitech Paris',
    description: `Epitech est une école d'informatique créée en 1999 qui forme en 5 ans après-bac des experts du secteur. Elle permet de transformer une passion en expertise via une pédagogie résolument innovante.
    Les étudiants sont plongés dans un écosystème à la fois national et international et dans un rythme pédagogique destiné à favoriser ouverture d’esprit, rencontres avec des influenceurs, des experts, afin de trouver le problème qu’ils chercheront à résoudre.

    Quelques compétences mises en oeuvre :

    ➤ EIP Innovative project - Top 10 des projet EPITECH expérience France
    ➤ Diagnostiquer la situation actuelle de l’entreprise et anticiper les évolutions.
    ➤ Accompagner le développement et le financement de son activité.
    ➤ Analyser les cibles de croissance externe et répondre aux besoins de financement.
    ➤ Forward : Inspiré du business model Canvas (générer de la valeur,`,
    source: Epitech,
    location: 'Paris - France',
    date: '2013 - 2019',
    bgColor: '#122767'
  },
  {
    title: 'Master of Science Degree in Engineering Management.',
    subtitle: 'Ahlia University',
    location: 'Manama - Bahrain',
    date: '2016 - 2017',
    description: `Fondée en 2001, Ahlia University est la première université agréée par le gouvernement du Bahrain
    Dès le départ, les fondateurs ont énoncé leur vision d’une institution distincte d’enseignement supérieur pour faire en sorte que les sciences humaines, le commerce et l'éducation occupent la place qui leur revient de droit dans la construction et le développement de notre société.
    
    Quelques compétences mises en oeuvre lors de ce cursus :
    
    ➤ Stratégies de management d'entreprise,
    ➤ Entrepreneuriat - Pitch & Conférences pendant la Journée Nationale de l'Entrepreneuriat à Ahlia University,
    ➤ Cisco, administration réseau et certification CCNA,
    ➤ .NET, Microsoft Developpers,
    ➤ Éthique en entreprise,
    ➤ Intelligence artificielle - Développement du projet Sara : Intelligence artificielle de traduction francophone`,
    source: Ahlia,
    bgColor: '#930B23'
  }
]

export const Mangas = [
  {
    src: getVideoUrl(SNKVideo),
    icon: Levi,
    thumb: ErenThumb,
    title: 'Attack on Titans',
  },
  {
    src: getVideoUrl(KurokoVideo),
    icon: Kuruko,
    thumb: KurukoThumb,
    title: 'Kuroko\'s Basket',
  },
  {
    src: getVideoUrl(DBZVideo),
    icon: Goku,
    thumb: GokuThumb,
    title: 'Dragon Ball Z',
  },
  {
    src: getVideoUrl(HXHVideo),
    icon: GonThumb,
    thumb: Gon,
    title: 'Hunter x Hunter',
  }
]

export const VideoGames: VideoGameType[] = [
  {
    title: 'League of legends',
    img: LeagueOfLegend,
    type: 'Favorite Game'
  },
  {
    title: 'Life Is Strange',
    img: LifeIsStrange,
    type: 'Best Story'
  },
  {
    title: 'Life Is Strange',
    img: LifeIsStrange,
    type: 'Best Story'
  },
  {
    title: 'Life Is Strange',
    img: LifeIsStrange,
    type: 'Best Story'
  },
]

export interface VideoGameType {
  title: string
  img: string
  type: string
}

export interface ModalPropsType {
  title: string
  stack?: string
  bgColor: string
  date: string
  subtitle: string
  mainTechno?: TechnicalStackKeys[]
  source: string
  location: string
  description: string
}

export const TVShowsData = [
  {
    name: 'Mr.Robot',
    image: MrRobot
  },
  {
    name: 'The Last Kingdom',
    image: TheLastKingdom
  },
  {
    name: 'La casa de papel',
    image: LaCasaDePapel
  },
  {
    name: 'Breaking Bad',
    image: BreakingBad
  },
  {
    name: 'Game of thrones',
    image: Got
  },
  {
    name: 'Brooklyn Nine Nine',
    image: Brooklyn99
  },
]

export interface TVShowInterface {
  name: string
  image: string
}
export interface PlaylistProps {
  title: string
  artist: string
  src: string
}